
import type { IErrorWithMessage } from 'shared/types/error/error-catch';

import axios, { AxiosError } from 'axios';
import { isEmpty } from 'lodash';
import { StoreDataStatus } from 'shared/enum/storeDataStatus';
import { APIError, instanceOfAPIError } from 'shared/interfaces/apiError';

export function isErrorWithMessage(error: unknown): error is IErrorWithMessage {
    return (
        typeof error === 'object' &&
        error !== null &&
        'message' in error &&
        typeof (error as Record<string, unknown>).message === 'string'
    );
}

export class KnownError extends Error {
    code?: string;

    constructor(message: string, code?: string, name?: string) {
        super(message);

        this.name    = name || 'KnownError';
        this.code    = code || 'KNOWN__ERROR';
        this.message = message;
    }
}

export const handleCatchBlock = (
    error: unknown,
    checkForAxiosError = false
): string | {data: APIError;error: AxiosError<APIError>} => {
    if (checkForAxiosError && axios.isAxiosError(error)) {
        const data = error?.response?.data ?? error;

        if (instanceOfAPIError(data)) {
            return {
                data: data as APIError,
                error: error as AxiosError<APIError>
            };
        }
    }

    if (error instanceof KnownError) {
        return `(${error.name}:${error.code}) ${String(error.message)}`;
    }

    if (isErrorWithMessage(error)) {
        return error.message;
    }

    return String(error);
};

export const doesRequestDataHasErrors = (data: any, searchForEmptyData = true) => {
    if (isEmpty(data) && searchForEmptyData) {
        return StoreDataStatus.NoData;
    }

    const hasErrors = instanceOfAPIError(data);

    if (hasErrors){
        return `[status:${data.statusCode}]:${data?.error ? data.error : data.message}`;
    }

    return null;
};
