export const GENERATE_HEATMAP = 'GENERATE_HEATMAP';
export const GENERATE_HEATMAP_SUCCESS = 'GENERATE_HEATMAP_SUCCESS';
export const GENERATE_HEATMAP_FAILURE = 'GENERATE_HEATMAP_FAILURE';
export const GENERATE_HEATMAP_IS_LOADING = 'GENERATE_HEATMAP_IS_LOADING';





export const SEARCH_HEATMAP_BY_PAGE_URL_SUCCESS = 'SEARCH_HEATMAP_BY_PAGE_URL_SUCCESS';

export const REMOVE_HEATMAP_SCREEN_SHOT_SUCCESS = 'REMOVE_HEATMAP_SCREEN_SHOT_SUCCESS';
export const REMOVE_HEATMAP_SCREEN_SHOT_FAILURE = 'REMOVE_HEATMAP_SCREEN_SHOT_FAILURE';

export const TAKE_NEW_SCREEN_SHOT_SUCCESS = 'TAKE_NEW_SCREEN_SHOT_SUCCESS';
export const TAKE_NEW_SCREEN_SHOT_FAILURE = 'TAKE_NEW_SCREEN_SHOT_FAILURE';



export const USER_HISTORY_SUCCESS = 'USER_HISTORY_SUCCESS';
export const USER_HISTORY_FAILURE = 'USER_HISTORY_FAILURE';

export const GET_HEATMAP_BY_PAGE_URL_SUCCESS = 'GET_HEATMAP_BY_PAGE_URL_SUCCESS';
export const GET_HEATMAP_BY_PAGE_URL_FAILURE = 'GET_HEATMAP_BY_PAGE_URL_FAILURE';

export const SELECT_HEATMAP_BY_DATE = 'SELECT_HEATMAP_BY_DATE';

export const SELECT_HEATMAP_BY_DEVICE  = 'SELECT_HEATMAP_BY_DEVICE';



export const REMOVE_HEATMAP_SUCCESS = 'REMOVE_HEATMAP_SUCCESS';
export const REMOVE_HEATMAP_FAILURE = 'REMOVE_HEATMAP_FAILURE';
